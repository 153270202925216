import { Injectable } from '@angular/core';
import { OfflineStorageService } from '../core/services/offline-storage.service';
import { ExibirLoteCompletoQueryResult } from './models/lote';
import { EnviarDocumentoLoteCommandParams, ListarDocumentosLoteQueryResultItem } from './models/documento-lote';
import { DocumentoLoteOfflineRequest, FotoLoteOffline, ListarDocumentosLoteOfflineQueryResultItem } from './models/documento-lote-offline';
import { TipoDocumentoLote } from './models/tipo-documento-lote';
import { AnotacaoOfflineRequest, ExibirAnotacoesOfflineQueryResult } from './models/anotacao-offline';
import { CadastrarAnotacaoCommandParams } from './models/anotacao';
import { ExibirLoteCompletoOfflineQueryResult } from './models/lote-offline';

@Injectable({
  providedIn: 'root'
})
export class LotesOfflineService {

  constructor(
    private offlineStorageService: OfflineStorageService
  ) { }

  public async sincronizacaoIniciada(loteId: number): Promise<ExibirLoteCompletoOfflineQueryResult> {
    const key = `LOTE_${loteId}`;

    let loteOffline: ExibirLoteCompletoOfflineQueryResult = (await this.offlineStorageService.get(key));
    loteOffline.possuiDadosPendentes = true;

    return this.offlineStorageService.save(key, loteOffline);
  }

  public async sincronizacaoFinalizada(loteId: number): Promise<ExibirLoteCompletoOfflineQueryResult> {
    const key = `LOTE_${loteId}`;

    let loteOffline: ExibirLoteCompletoOfflineQueryResult = (await this.offlineStorageService.get(key));
    loteOffline.possuiDadosPendentes = false;

    return this.offlineStorageService.save(key, loteOffline);
  }

  public save(lote: ExibirLoteCompletoQueryResult): Promise<ExibirLoteCompletoOfflineQueryResult> {
    let newLote: ExibirLoteCompletoOfflineQueryResult = {
      key: `LOTE_${lote.id}`,
      ...lote
    };

    return this.offlineStorageService.save(newLote.key, newLote);
  }

  public delete(loteId: number): Promise<void> {
    const key =  `LOTE_${loteId.toString()}`;
    return this.offlineStorageService.delete(key);
  }

  public async get(loteId: number | string): Promise<ExibirLoteCompletoOfflineQueryResult> {
    const key =  `LOTE_${loteId.toString()}`;
    return this.offlineStorageService.get(key);
  }

  public async getAll(): Promise<ExibirLoteCompletoOfflineQueryResult[]> {
    return this.offlineStorageService.getAll('LOTE_');
  }

  public async getAllFromNucleoUrbano(nucleoUrbanoId: number): Promise<ExibirLoteCompletoOfflineQueryResult[]> {
    const lotes = await this.offlineStorageService.getAll('LOTE_');

    let lotesFromNucleoUrbano: ExibirLoteCompletoOfflineQueryResult[] = [];

    lotes.forEach((lote) => {
      if (lote.nucleoUrbanoId === nucleoUrbanoId) {
        lotesFromNucleoUrbano.push(lote);
      }
    });

    return lotesFromNucleoUrbano;
  }

  //
  // DOCUMENTOS LOTE

  public async getAllDocumentos(loteKey: string): Promise<ListarDocumentosLoteOfflineQueryResultItem[]> {
    const key_pattern = `DOCUMENTO_LOTE_${loteKey.toString()}_ID_`;
    const documentosFromLote = await this.offlineStorageService.getAll(key_pattern);

    return documentosFromLote;
  }

  public deleteDocumento(key: string): Promise<void> {
    return this.offlineStorageService.delete(key);
  }

  public async saveDocumentoRequest(loteId: number, dadosDocumento: EnviarDocumentoLoteCommandParams): Promise<DocumentoLoteOfflineRequest> {
    const keyPattern = `DOCUMENTO_REQUEST_LOTE_${loteId}`;
    let identificador = `${keyPattern}_${Math.random().toString()}`;
    
    let identificadorJaExiste = await this.offlineStorageService.get(identificador);

    while (identificadorJaExiste) {
      identificador = `${keyPattern}_${Math.random().toString()}`;
      identificadorJaExiste = await this.offlineStorageService.get(identificador);
    }

    let documentoLoteRequest: DocumentoLoteOfflineRequest = {
      key: identificador,
      ...dadosDocumento
    };

    return this.offlineStorageService.save(documentoLoteRequest.key, documentoLoteRequest);
  }

  public async getDocumentoRequest(key: string): Promise<DocumentoLoteOfflineRequest> {
    const documentoRequest = (await this.offlineStorageService.get(key));
    return documentoRequest;
  }

  public async getAllDocumentoRequests(loteId: number): Promise<DocumentoLoteOfflineRequest[]> {
    const keyPattern = `DOCUMENTO_REQUEST_LOTE_${loteId}`;
    const documentosFromRequests = await this.offlineStorageService.getAll(keyPattern);

    return documentosFromRequests;
  }

  public deleteDocumentoRequest(documentoKey: string): Promise<any> {
    return this.offlineStorageService.delete(documentoKey);
  }

  //
  // SALVAR FOTO DO LOTE

  public async saveFotoLote(loteId: number, dadosDocumento: EnviarDocumentoLoteCommandParams, jaExistente: boolean): Promise<FotoLoteOffline> {
    const keyPattern = `FOTO_LOTE_${loteId}`;

    let fotoLote: FotoLoteOffline = {
      key: keyPattern,
      jaExistente: jaExistente,
      ...dadosDocumento
    };

    console.log('SAVING FOTO DO LOTE:')
    console.log(dadosDocumento)

    return this.offlineStorageService.save(fotoLote.key, fotoLote);
  }

  //
  // BUSCAR FOTO DO LOTE OFFLINE

  public async buscarFotoLote(loteId: number): Promise<FotoLoteOffline | null> {
    const keyPattern = `FOTO_LOTE_${loteId}`;

    return this.offlineStorageService.get(keyPattern);
  }

  //
  // ANOTAÇÕES

  public deleteAnotacao(key: string): Promise<any> {
    return this.offlineStorageService.delete(key);
  }

  public async getAnotacoesFromLote(loteId: number): Promise<ExibirAnotacoesOfflineQueryResult[]> {
    const key_pattern = `LOTE_${loteId.toString()}`;
    const anotacoes: ExibirAnotacoesOfflineQueryResult[] | null = (await this.offlineStorageService.get(key_pattern)).anotacoes || null;

    return anotacoes || [];
  }

  public async saveAnotacaoRequest(loteId: number, dadosAnotacao: CadastrarAnotacaoCommandParams): Promise<any> {
    const keyPattern = `ANOTACAO_REQUEST_LOTE_${loteId}`;
    let identificador = `${keyPattern}_${Math.random().toString()}`;
    
    let identificadorJaExiste = await this.offlineStorageService.get(identificador);

    while (identificadorJaExiste) {
      identificador = `${keyPattern}_${Math.random().toString()}`;
      identificadorJaExiste = await this.offlineStorageService.get(identificador);
    }

    let anotacaoRequest: AnotacaoOfflineRequest = {
      key: identificador,
      ...dadosAnotacao
    };

    return this.offlineStorageService.save(anotacaoRequest.key, anotacaoRequest);
  }

  public async getAnotacaoRequestsFromLote(loteId: number): Promise<ExibirAnotacoesOfflineQueryResult[]> {
    const keyPattern = `ANOTACAO_REQUEST_LOTE_${loteId}`;
    const documentosFromRequests = await this.offlineStorageService.getAll(keyPattern);
    return documentosFromRequests;
  }

  //
  // No momento o agente não possui o id,
  // portanto o nome de usuário está sendo usando como identificador
  
  public saveAgente(agente: any): Promise<any> {
    const newAgente = {
      //key: 'AGENTE_' + agente.id,
      key: 'AGENTE_' + agente.username,
      data: agente
    };

    return this.offlineStorageService.save(newAgente.key, newAgente);
  }

  public async getAgentes(): Promise<any[]> {
    return this.offlineStorageService.getAll('AGENTE_');
  }

}

import { Injectable } from '@angular/core';
import { OfflineStorageService } from 'src/app/core/services/offline-storage.service';
import { ExibirLoteCompletoOfflineQueryResult } from '../models/lote-offline';
import { CadastrarVisitaCommandParams, EditarVisitaCommandParams, EnviarDocumentoVisitaCommandParams, ExibirVisitasQueryResult, ListarDocumentosVisitaQueryResultItem } from './models/visita';
import { DocumentoVisitaOfflineRequest, VisitaOfflineRequest } from './models/visitas-offline';

@Injectable({
  providedIn: 'root'
})
export class VisitasOfflineService {

  constructor(
    private offlineStorageService: OfflineStorageService
  ) { }

  public async getVisitaOffline(loteId: number, visitaId: number): Promise<ExibirVisitasQueryResult | null> {
    const lote: ExibirLoteCompletoOfflineQueryResult = await this.offlineStorageService.get(`LOTE_${loteId}`);
    const visita = lote.visitas.find((visitaLote) => visitaLote.id === visitaId) || null;

    return visita || null;
  }

  public async getAllVisitasOfflineFromLote(loteId: number): Promise<ExibirVisitasQueryResult[]> {
    const key_pattern = `LOTE_${loteId.toString()}`;
    const visitasFromLote: ExibirVisitasQueryResult[] | null = (await this.offlineStorageService.get(key_pattern)).visitas || null;

    return visitasFromLote || [];
  }

  // public getDocumentoVisita(documentoKey: string): Promise<ListarDocumentosVisitaQueryResultItem> {
  //   return this.offlineStorageService.get(documentoKey);
  // }

  public async getAllDocumentosVisita(visitaId: number | string): Promise<ListarDocumentosVisitaQueryResultItem[]> {
    const key_pattern = `DOCUMENTO_VISITA_${visitaId}_`;
    const documentosFromVisita = await this.offlineStorageService.getAll(key_pattern);

    return documentosFromVisita;
  }
  
  public async saveVisitaRequest(dadosVisita: CadastrarVisitaCommandParams): Promise<VisitaOfflineRequest> {
    const keyPattern = `VISITA_REQUEST`;
    let identificador = `${keyPattern}_${Math.random().toString()}`;
    
    let identificadorJaExiste = await this.offlineStorageService.get(identificador);

    while (identificadorJaExiste) {
      identificadorJaExiste = await this.offlineStorageService.get(identificador);
    }

    let visitaRequest: VisitaOfflineRequest = {
      key: identificador,
      visitaJaCadastrada: false,
      ...dadosVisita
    };

    return this.offlineStorageService.save(visitaRequest.key, visitaRequest);
  }

  public async editVisitaRequest(visitaRequestEditada: VisitaOfflineRequest): Promise<VisitaOfflineRequest> {
    return this.offlineStorageService.save(visitaRequestEditada.key, visitaRequestEditada);
  }

  public async setVisitaRequestAsCadastrada(requestKey: string, visitaId: number): Promise<VisitaOfflineRequest> {
    let request: VisitaOfflineRequest = (await this.offlineStorageService.get(requestKey));

    request.visitaCadastradaId = visitaId;
    request.visitaJaCadastrada = true;

    return this.offlineStorageService.save(request.key, request);
  }

  public async getVisitaRequest(requestKey: string): Promise<VisitaOfflineRequest> {
    const request = (await this.offlineStorageService.get(requestKey));

    return request;
  }

  public async getAllVisitaRequestsFromLote(loteId: number): Promise<VisitaOfflineRequest[]> {
    const keyPattern = `VISITA_REQUEST`;
    const visitaRequests = (await this.offlineStorageService.getAll(keyPattern)).filter(visitaRequest => visitaRequest.loteId === loteId);

    return visitaRequests;
  }

  public deleteVisitaRequest(key: string): Promise<void> {
    return this.offlineStorageService.delete(key);
  }

  public async saveDocumentoRequestVisita(identificadorVisita: number | string, dadosDocumento: EnviarDocumentoVisitaCommandParams): Promise<DocumentoVisitaOfflineRequest> {
    const keyPattern = `DOCUMENTO_REQUEST_VISITA_${identificadorVisita}`;
    let identificador = `${keyPattern}_${Math.random().toString()}`;
    
    let identificadorJaExiste = await this.offlineStorageService.get(identificador);

    while (identificadorJaExiste) {
      identificador = `${keyPattern}_${Math.random().toString()}`;
      identificadorJaExiste = await this.offlineStorageService.get(identificador);
    }

    let documentoVisitaRequest: DocumentoVisitaOfflineRequest = {
      key: identificador,
      ...dadosDocumento
    };

    return this.offlineStorageService.save(documentoVisitaRequest.key, documentoVisitaRequest);
  }

  public async getDocumentoRequest(requestKey: string): Promise<DocumentoVisitaOfflineRequest> {
    const request: DocumentoVisitaOfflineRequest = (await this.offlineStorageService.get(requestKey));

    return request || null;
  }

  public async getAllDocumentoRequests(visitaKey: string): Promise<DocumentoVisitaOfflineRequest[]> {
    const keyPattern = `DOCUMENTO_REQUEST_VISITA_${visitaKey}`;
    const documentosFromRequests = await this.offlineStorageService.getAll(keyPattern);

    return documentosFromRequests;
  }

  public deleteDocumentoRequest(documentoKey: string): Promise<void> {
    return this.offlineStorageService.delete(documentoKey);
  }

  //
  // No momento o agente não possui o id,
  // portanto o nome de usuário está sendo usando como identificador
  //
  public saveAgente(agente: any): Promise<any> {
    const newAgente = {
      //key: 'AGENTE_' + agente.id,
      key: 'AGENTE_' + agente.username,
      data: agente
    };

    return this.offlineStorageService.save(newAgente.key, newAgente);
  }

  public async getAgentes(): Promise<any[]> {
    return this.offlineStorageService.getAll('AGENTE_');
  }

}

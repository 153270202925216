import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { InteressadosOfflineService } from 'src/app/lotes/interessados/interessados-offline.service';
import { ExibirInteressadoCompletoQueryResult } from 'src/app/lotes/interessados/models/interessado';
import { NovoInteressadoOfflineCommandParams } from 'src/app/lotes/interessados/models/interessado-offline';
import { LotesOfflineService } from 'src/app/lotes/lotes-offline.service';
import { ExibirLoteCompletoOfflineQueryResult } from 'src/app/lotes/models/lote-offline';
import { VisitaOfflineRequest } from 'src/app/lotes/visitas/models/visitas-offline';
import { VisitasOfflineService } from 'src/app/lotes/visitas/visitas-offline.service';
import { ExibirNucleoUrbanoOfflineQueryResult } from 'src/app/nucleos-urbanos/models/nucleo-urbano-offline';
import { NucleosUrbanosOfflineService } from 'src/app/nucleos-urbanos/nucleos-urbanos-offline.service';
import { LoadedEntityService } from 'src/app/shared/services/loaded-entity.service';
@Component({
  selector: 'app-share-target',
  templateUrl: './share-target.component.html',
  styleUrls: ['./share-target.component.scss']
})
export class ShareTargetComponent implements OnInit, OnDestroy {

  trashCanIcon = faTrashCan;

  public imageUrls: string[] = [];

  sendingRequest: boolean = false;

  nucleosUrbanosOffline: ExibirNucleoUrbanoOfflineQueryResult[] = [];
  loadingNucleosUrbanosOffline: boolean = true;

  lotesOffline: ExibirLoteCompletoOfflineQueryResult[] = [];
  loadingLotesOffline: boolean = false;

  interessadosOffline: (NovoInteressadoOfflineCommandParams | ExibirInteressadoCompletoQueryResult)[] = [];
  loadingInteressadosOffline: boolean = false;

  visitaRequests: (VisitaOfflineRequest)[] = [];
  loadingVisitaRequests: boolean = false;
  loadingInteressados: boolean = false;

  documentosInteressado: {label: string, value: string}[] = [];

  gruposDocumentosInteressado: {label: string, value: string}[] = [
    {
      label: 'Comprovante de Identidade',
      value: 'comprovantesIdentidade'
    },
    {
      label: 'Comprovante de Estado Civil',
      value: 'comprovantesEstadoCivil'
    },
    {
      label: 'Comprovante de Propriedade',
      value: 'comprovantesPropriedade'
    },
    {
      label: 'Diversos',
      value: 'diversos'
    }
  ];

  gruposTiposDocumentoInteressado: { [key: string]: any[] } = {
    'comprovantesIdentidade': [
      {
        label: 'RG',
        value: 'RG'
      },
      {
        label: 'CPF',
        value: 'CPF'
      }
    ],
    'comprovantesEstadoCivil': [
      {
        label: 'Certidão de Nascimento',
        value: 'CERTIDAO_DE_NASCIMENTO'
      },
      {
        label: 'Certidão de Casamento',
        value: 'CERTIDAO_DE_CASAMENTO'
      },
      {
        label: 'Certidão de Óbito',
        value: 'CERTIDAO_DE_OBITO'
      },
      {
        label: 'Outro Comprovante',
        value: 'OUTRO_COMPROVANTE_DE_ESTADO_CIVIL'
      }
    ],
    'comprovantesPropriedade': [
      {
        label: 'Comprovante de Residência Anterior ao Marco Legal',
        value: 'COMPROVANTE_DE_RESIDENCIA_ANTERIOR_AO_MARCO_LEGAL'
      },
      {
        label: 'Contrato de Compra e Venda',
        value: 'CONTRATO_DE_COMPRA_E_VENDA'
      },
      {
        label: 'Termo de Doação',
        value: 'TERMO_DE_DOACAO'
      },
      {
        label: 'Procuração Pública',
        value: 'PROCURACAO_PUBLICA'
      },
      {
        label: 'Declaração da Associação de Moradores',
        value: 'DECLARACAO_DA_ASSOCIACAO_DE_MORADORES'
      },
      {
        label: 'Outro comprovante',
        value: 'OUTRO_COMPROVANTE_DE_PROPRIEDADE'
      }
    ],
    'diversos': [
      {
        label: 'Formulário de Atendimento',
        value: 'FORMULARIO_DE_ATENDIMENTO'
      },
      {
        label: 'Foto do Interessado no Imóvel',
        value: 'FOTO_INTERESSADO_NO_IMOVEL'
      },
      {
        label: 'Comprovante de Residência Atual',
        value: 'COMPROVANTE_DE_RESIDENCIA_ATUAL'
      },
      {
        label: 'Declaração de Moradia',
        value: 'DECLARACAO_DE_MORADIA'
      },
      {
        label: 'Declaração de Anuência da Área',
        value: 'DECLARACAO_DE_ANUENCIA_DA_AREA'
      },
      {
        label: 'Requerimento de Registro em Nome do Casal',
        value: 'REQUERIMENTO_DE_REGISTRO_EM_NOME_DO_CASAL'
      },
      {
        label: 'Outro',
        value: 'OUTRO'
      }
    ]
  };

  private DESTINO_VISITA_INDEX = 0 as const;
  private DESTINO_INTERESSADO_INDEX = 1 as const;

  destinosArquivo: {val: 'VISITA' | 'INTERESSADO', label: string}[] = [
    { val: 'VISITA', label: 'Visita' },
    { val: 'INTERESSADO', label: 'Interessado' },
  ];

  sendingFiles: boolean = false;

  submitted: boolean = false;
  form: FormGroup = new FormGroup('');

  private nucleoUrbanoValueChanges: Subscription = new Subscription();
  private loteValueChanges: Subscription = new Subscription();
  private destinoArquivoValueChanges: Subscription = new Subscription();

  private INDEXEDDB_STORE_NAME: string = 'local-store-data' as const;
  private INDEXEDDB_OBJECT_STORE_NAME: string = 'local-shared-images' as const;

  constructor(
    private nucleosUrbanosOfflineService: NucleosUrbanosOfflineService,
    private lotesOfflineService: LotesOfflineService,
    private visitasOfflineService: VisitasOfflineService,
    private interessadosOfflineService: InteressadosOfflineService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,

    private loadedEntityService: LoadedEntityService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      nucleoUrbano: [null, Validators.required],
      lote: [{value: null, disabled: true}, Validators.required],
      destinoArquivo: [{value: null, disabled: true}, Validators.required],

      visita: [{value: null, disabled: true}, Validators.required],
      interessado: [{value: null, disabled: true}, Validators.required],

      images: this.fb.array([])
    });

    this.loadImages();
    this.getNucleosUrbanosOffline();

    this.nucleoUrbanoValueChanges = this.form?.controls['nucleoUrbano']?.valueChanges.subscribe({
      next: (value) => {
        if (value) {
          const selectedNucleoUrbanoId = value;

          this.form.get('lote')?.enable();
          this.getLotesOffline(selectedNucleoUrbanoId);
        } else {
          this.form.get('lote')?.disable();
        }
      }
    });

    this.loteValueChanges = this.form?.controls['lote']?.valueChanges.subscribe({
      next: (value) => {
        if (value) {
          const selectedLoteId = value;

          this.getInteressadosOffline(selectedLoteId);
          this.getVisitaRequests(selectedLoteId);

          this.form.get('destinoArquivo')?.enable();
        } else {
          this.form.get('destinoArquivo')?.disable();
        }
      }
    });

    this.destinoArquivoValueChanges = this.form?.controls['destinoArquivo']?.valueChanges.subscribe({
      next: (value) => {
        if (value) {
          const destinoArquivo = value.val;
          const selectedLoteId = this.form.get('lote')?.value;

          if (!selectedLoteId)
            return;

          if (destinoArquivo === 'VISITA') {
            this.form.get('visita')?.enable();
            this.form.get('interessado')?.disable();

            this.form.get('visita')?.setValue(null);
            this.form.get('interessado')?.setValue(null);

            this.images.controls.forEach(control => {
              control.get('tipoDocumento')?.disable();
            });

            return;
          }

          if (destinoArquivo === 'INTERESSADO') {
            this.form.get('interessado')?.enable();
            this.form.get('visita')?.disable();

            this.form.get('visita')?.setValue(null);
            this.form.get('interessado')?.setValue(null);

            this.images.controls.forEach(control => {
              control.get('tipoDocumento')?.enable();
            });

            return;
          }
          
        }
      }
    });

    const loadedData = this.loadedEntityService.loadedData;

    if (loadedData.loadedEntity === 'INTERESSADO') {
      this.form.patchValue({
        destinoArquivo: this.destinosArquivo[this.DESTINO_INTERESSADO_INDEX]
      });
    }

    if (loadedData.loadedEntity === 'VISITA') {
      this.form.patchValue({
        destinoArquivo: this.destinosArquivo[this.DESTINO_VISITA_INDEX]
      });
    }

    this.form.patchValue({
      nucleoUrbano: loadedData.nucleoUrbanoId ? Number(loadedData.nucleoUrbanoId) : null,
      lote: loadedData.loteId ? Number(loadedData.loteId) : null,
      visita: loadedData.visitaId,
      interessado: loadedData.interessadoId
    });
  }

  ngOnDestroy(): void {
    this.nucleoUrbanoValueChanges.unsubscribe();
    this.loteValueChanges.unsubscribe();
    this.destinoArquivoValueChanges.unsubscribe();
  }

  public async save(): Promise<void> {
    this.submitted = true;

    if (!this.form.valid) {
      this.markAllAsDirty(Object.values(this.form.controls));
      return
    }

    this.sendingFiles = true;

    const nucleoUrbanoId = this.form.value.nucleoUrbano;
    const loteId = this.form.value.lote;
    
    if (this.form.get('visita')?.enabled) {
      const visitaKey = this.form.value.visita;

      await this.sendImagensVisita(nucleoUrbanoId, loteId, visitaKey);

      return;
    }

    if (this.form.get('interessado')?.enabled) {
      const interessadoIdentifier = (this.form.value.interessado.id ? this.form.value.interessado.id : this.form.value.interessado.key);

      await this.sendImagensInteressado(nucleoUrbanoId, loteId, interessadoIdentifier);
      return;
    }

    this.sendingFiles = false;
  }

  private async sendImagensVisita(nucleoUrbanoId: number, loteId: number, visitaKey: string): Promise<void> {
    try {
      let imagens: any[] = [];

      this.images.controls.forEach((control) => {
        const nome = control.value.filename;
        const tipo = control.value.type;
        const descricao = '';
        
        const file = this.convertUrlToFile(control.value.imageUrl, nome, tipo);

        const imageParams = {
          nome: nome,
          descricao: descricao,
          file: file
        };

        imagens.push(imageParams);
      });

      for (let image of imagens) {
        try {
          await this.visitasOfflineService.saveDocumentoRequestVisita(visitaKey, image);
        } catch (error: any) {
          throw error;
        }
      }

      this.sendingFiles = false;

      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Arquivo enviado com sucesso'
      });

      this.router.navigate([ '/', 'nucleos-urbanos', 'exibir', String(nucleoUrbanoId),
                              'lotes', 'exibir', String(loteId),
                              'visitas', 'exibir', String(visitaKey) ]);
    } catch (error: any) {
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: 'Erro',
        detail: 'Falha ao enviar o arquivo: ' + error.message
      });

      this.sendingFiles = false;
    }
  }

  private async sendImagensInteressado(nucleoUrbanoId: number, loteId: number, interessadoIdentifier: string): Promise<void> {
    try {
      let imagens: any[] = [];

      this.images.controls.forEach((control) => {
        const nome = control.value.filename;
        const tipo = control.value.type;
        const tipoDocumento = control.value.tipoDocumento;
        
        const file = this.convertUrlToFile(control.value.imageUrl, nome, tipo);

        const imageParams = {
          nome: nome,
          tipoDocumento: tipoDocumento,
          file: file,
          descricao: ''
        };

        imagens.push(imageParams);
      });

      for (let image of imagens) {
        try {
          await this.interessadosOfflineService.saveDocumentoRequestInteressado(interessadoIdentifier, image);
        } catch (error: any) {
          throw error;
        }
      }

      this.sendingFiles = false;

      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Arquivo enviado com sucesso'
      });

      const redirectLink = this.form.value.interessado.key;

      this.router.navigate([ '/', 'nucleos-urbanos', 'exibir', String(nucleoUrbanoId),
                              'lotes', 'exibir', String(loteId),
                              'interessados', 'exibir', String(redirectLink) ]);
    } catch (error: any) {
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: 'Erro',
        detail: 'Falha ao enviar o arquivo: ' + error.message
      });

      this.sendingFiles = false;
    }
  }

  public onChangeGrupoDocumento(value: any): void {
    if (value) {
      const grupoDocumento = String(value);
      const tiposDocumento = this.gruposTiposDocumentoInteressado[grupoDocumento];

      if (tiposDocumento)
        this.documentosInteressado = tiposDocumento;

      console.log(this.documentosInteressado);
    }
  }

  private loadImages(): void {
    if (!window.indexedDB) {
      throw Error('IndexedDB Indisponível');
    }

    const openDBRequest = indexedDB.open(this.INDEXEDDB_STORE_NAME);

    openDBRequest.onupgradeneeded = (event: any) => {
        const db = event.target.result;

        const currentVersion = db.version;
        console.log(currentVersion);

        const sharedImagesStore = db.createObjectStore(this.INDEXEDDB_OBJECT_STORE_NAME, {keyPath: 'key'});
    }

    openDBRequest.onsuccess = (event: any) => {
        const db = event.target.result;

        const transaction = db.transaction(this.INDEXEDDB_OBJECT_STORE_NAME, "readonly");
        const objectStore = transaction.objectStore(this.INDEXEDDB_OBJECT_STORE_NAME);

        objectStore.openCursor().onsuccess = (event: any) => {
            let cursor = event.target.result;
            if (cursor) {
              const key = cursor.key;

              const imageUrl = cursor.value.data.content;
              const type = cursor.value.data.type;
              const filename = cursor.value.data.filename;

              this.adicionarImagem(imageUrl, filename, type);
              this.imageUrls.push(imageUrl);

              cursor.continue();
            }
        };

        transaction.oncomplete = function () {
            db.close();
        };

        this.clearStoreData(db);
    };

    openDBRequest.onerror = (event: any) => {
        throw Error(event);
    };
  }

  private clearStoreData(db: any): void {
    const transaction = this.createTransaction(db);
    const store = transaction.objectStore(this.INDEXEDDB_OBJECT_STORE_NAME);

    const query = store.clear();

    query.onsuccess = function (event: any) {
        console.log(event);
    };

    query.onerror = function (event: any) {
        throw Error(event);
    }
  }

  private createTransaction(db: any) {
    const transaction = db.transaction([this.INDEXEDDB_OBJECT_STORE_NAME], "readwrite");
    transaction.onerror = (event: any) => {
        throw Error(event);
    };

    transaction.oncomplete = (event: any) => {
        db.close();
    };

    return transaction;
  }

  public getNucleosUrbanosOffline() {
    this.loadingNucleosUrbanosOffline = true;

    this.nucleosUrbanosOfflineService.getAllNucleosUrbanos()
    .then((nucleosUrbanosOffline) => {
      this.nucleosUrbanosOffline = nucleosUrbanosOffline;

      console.log('Núcleos Urbanos: ', nucleosUrbanosOffline);
      this.loadingNucleosUrbanosOffline = false;
    })
    .catch((e) => {
      this.messageService.add({
        severity: 'error',
        summary: 'Erro',
        detail: e.message,
        sticky: true
      });

      this.loadingNucleosUrbanosOffline = false;
    });
  }

  public getLotesOffline(nucleoUrbanoId: number) {
    this.loadingLotesOffline = true;

    this.lotesOfflineService.getAllFromNucleoUrbano(nucleoUrbanoId)
    .then((lotes) => {
      this.lotesOffline = lotes;
      this.loadingLotesOffline = false;

      console.log('Lotes: ', this.lotesOffline);
    })
    .catch((e) => {
      this.messageService.add({
        severity: 'error',
        summary: 'Erro',
        detail: 'Erro ao listar lotes offline: ' + e.message
      });

      this.loadingLotesOffline = false;
    })
  }

  public async getInteressadosOffline(loteId: number): Promise<void> {
    this.loadingInteressados = true;

    try {
      const interessadosFromLote = await this.interessadosOfflineService.getAllInteressadosOfflineFromLote(loteId);
      const interessadoRequestsFromLote = await this.interessadosOfflineService.getAllInteressadosRequestsFromLote(loteId);

      this.interessadosOffline = [ ...interessadosFromLote, ...interessadoRequestsFromLote ];

      const interessadoControl = this.form.get('interessado');

      if (interessadoControl && interessadoControl.value && typeof interessadoControl.value === 'string') {
        const loadedInteressado = this.interessadosOffline.find((item) => {
          if ((item as ExibirInteressadoCompletoQueryResult).id) {
            return (item as ExibirInteressadoCompletoQueryResult).id === interessadoControl.value;
          }

          if ((item as NovoInteressadoOfflineCommandParams).key) {
            return (item as NovoInteressadoOfflineCommandParams).key === interessadoControl.value;
          }

          return false;
        });

        this.form.patchValue({
          interessado: loadedInteressado || null
        });
      }

      this.loadingInteressados = false;
    } catch(e: any) {
      console.log(e);

      this.messageService.add({
        severity: 'error',
        summary: 'Erro',
        detail: 'Erro ao listar interessados localmente: ' + e.message
      });

      this.loadingInteressados = false;
    }
  }

  public async getVisitaRequests(loteId: number): Promise<void> {
    this.loadingVisitaRequests = true;

    try {
      const visitaRequestsFromLote = (await this.visitasOfflineService.getAllVisitaRequestsFromLote(loteId));

      this.visitaRequests = visitaRequestsFromLote;

      this.loadingVisitaRequests = false;
    } catch(e: any) {
      console.log(e);

      this.messageService.add({
        severity: 'error',
        summary: 'Erro',
        detail: 'Erro ao listar visitas localmente: ' + e.message
      });

      this.loadingVisitaRequests = false;
    }

    console.log('Found visitas offline: ', this.visitaRequests);

  }

  public adicionarImagem(imageUrl: string, filename: string, type: string): void {
    this.images.push(this.addImagemFormGroup(imageUrl, filename, type));
  }

  public addImagemFormGroup(imageUrl: string, filename: string, type: string): FormGroup {
    return this.fb.group({
      filename: [filename, Validators.required],
      type: [type, Validators.required],
      imageUrl: [imageUrl, Validators.required],
      tipoDocumento: [null, Validators.required]
    });
  }

  public removerImagem(index: number): void {
    this.images.removeAt(index);
  }

  get images(): FormArray {
    return (this.form.get('images') as FormArray);
  }

  get imagesFormArrayControls() {
    return this.images.controls as FormGroup[];
  }

  // Marca todos os campos como "modificados", mudando o estilo do formulário
  // conforme sua verificação
  //
  private markAllAsDirty(control: AbstractControl[]): void {
    control.forEach((control) => {
      if (control instanceof FormControl) {
        control.markAsDirty();
      } else if (control instanceof FormGroup) {
        this.markAllAsDirty(Object.values(control.controls));
      } else if (control instanceof FormArray) {
        this.markAllAsDirty(control.controls);
      }
    });
  }

  private convertUrlToFile(fileUrl: string, filename: string, type: string): File {
    const fileContent = String(fileUrl).split(',');

    const fileDataBase64 = fileContent[1];

    const filetype = type;

    const metadata = {
      type: filetype
    };

    // Convert base64 data to Blob
    const byteCharacters = atob(fileDataBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], metadata);
    const file = new File([blob], filename, metadata);

    return file;
  }

}

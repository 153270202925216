import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NetworkStatusService } from './network-status.service';
import { NgForageCache, NgForage } from 'ngforage'
import { AuthenticationService } from './authentication.service';
import { User } from '../auth/user';

@Injectable({
  providedIn: 'root'
})
export class OfflineStorageService {
  
  constructor(
    private http: HttpClient,
    private networkStatusService: NetworkStatusService,
    private authService: AuthenticationService,
    private readonly ngf: NgForage,
    private readonly cache: NgForageCache
  ) {

    // this.ngf.name = 'local-store-coleta';

    authService.getUser().subscribe({
      next: (user: User) => {
        const username = user.username;
        const localStorageName = 'local-store-coleta-' + username;

        this.ngf.name = localStorageName;

        console.log('Local storage: ', localStorageName);
      },
      error: (error: any) => {
        console.log(error);
        throw error;
      }
    });

  }

  public save<T>(key: string, newData: T): Promise<T> {
    return this.ngf.setItem(key, newData);
  }

  public delete(key: string): Promise<void> {
    return this.ngf.removeItem(key);
  }

  public async get(key: string) {
    return this.ngf.getItem<any>(key);
  }

  public async getAll(keyPattern = ''): Promise<any[]> {
    let vals: any[] = [];
    await this.ngf.iterate((val: any, key) => {
      if (key.indexOf(keyPattern) === 0)
        vals.push({ key: key, ...val });
    });

    return vals;
  }

  public syncRequests() {
    if (this.networkStatusService.getConnectionStatus() === true) {
      this.ngf.iterate((value: any, key: string, itNum: number): void => {
        if (key.indexOf('REQUEST') > -1) {
          if (value.action === 'POST') {

            const httpOptions = {
              headers: new HttpHeaders({ 'Content-Type': 'application/json' })
            };
  
            this.http.post<any>(value.url, value.data, httpOptions)
            .subscribe({
              next: () => {
                this.ngf.removeItem(key);
              },
              error: (error: any) => {
                console.log(error);
              }
            });
  
          } else if (value.action === 'DELETE') {
  
            const httpOptions = {
              headers: new HttpHeaders({ 'Content-Type': 'application/json' })
            };
  
            this.http.delete<any>(value.url, httpOptions)
              .subscribe({
                next: () => {
                  this.ngf.removeItem(key);
                }
              });
          }
        } else {

        }
        
      })
      .then(() => {

      });
    }
  }

}

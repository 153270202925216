import { Injectable } from '@angular/core';
import { OfflineStorageService } from 'src/app/core/services/offline-storage.service';
import { CadastrarInteressadoCommandParams, ExibirInteressadoCompletoQueryResult } from './models/interessado';
import { ExibirLoteCompletoOfflineQueryResult } from '../models/lote-offline';
import { NovoInteressadoOfflineCommandParams } from './models/interessado-offline';
import { EnviarDocumentoInteressadoCommandParams, ListarDocumentosInteressadoQueryResultItem } from './models/interessado-documento';
import { NovoDocumentoInteressadoOfflineCommandParams } from './models/interessado-documento-offline';

@Injectable({
  providedIn: 'root'
})
export class InteressadosOfflineService {

  constructor(
    private offlineStorageService: OfflineStorageService
  ) { }

  public async getInteressadoOffline(loteId: number, interessadoId: number): Promise<ExibirInteressadoCompletoQueryResult | null> {
    const lote: ExibirLoteCompletoOfflineQueryResult = await this.offlineStorageService.get(`LOTE_${loteId}`);

    const interessado = lote.interessados.find((interessadoLote) => interessadoLote.id === interessadoId) || null;

    return interessado || null;
  }

  public async getAllInteressadosOfflineFromLote(loteId: number): Promise<ExibirInteressadoCompletoQueryResult[]> {
    const key_pattern = `LOTE_${loteId.toString()}`;
    const interessadosFromLote: ExibirInteressadoCompletoQueryResult[] | null = (await this.offlineStorageService.get(key_pattern)).interessados || null;

    return interessadosFromLote || [];
  }

  public async saveInteressadoRequest(dadosInteressado: CadastrarInteressadoCommandParams): Promise<NovoInteressadoOfflineCommandParams> {
    const keyPattern = `INTERESSADO_REQUEST`;
    let identificador = `${keyPattern}_${Math.random().toString()}`;
    
    let identificadorJaExiste = await this.offlineStorageService.get(identificador);

    while (identificadorJaExiste) {
      identificador = `${keyPattern}_${Math.random().toString()}`;
      identificadorJaExiste = await this.offlineStorageService.get(identificador);
    }

    let interessadoRequest: NovoInteressadoOfflineCommandParams = {
      key: identificador,
      interessadoJaCadastrado: false,
      ...dadosInteressado
    };

    return this.offlineStorageService.save(interessadoRequest.key, interessadoRequest);
  } 

  public deleteInteressadoRequest(key: string): Promise<void> {
    return this.offlineStorageService.delete(key);
  }

  public async getInteressadoRequest(requestKey: string): Promise<NovoInteressadoOfflineCommandParams> {
    const request = (await this.offlineStorageService.get(requestKey));

    return request;
  }

  public async getAllInteressadosRequestsFromLote(loteId: number): Promise<NovoInteressadoOfflineCommandParams[]> {
    const keyPattern = `INTERESSADO_REQUEST_`;
    const interessadoRequests = (await this.offlineStorageService.getAll(keyPattern)).filter(interessadoRequest => interessadoRequest.loteId === loteId);

    return interessadoRequests;
  }

  public async editInteressadoRequest(interessadoRequestEditada: NovoInteressadoOfflineCommandParams): Promise<NovoInteressadoOfflineCommandParams> {
    return this.offlineStorageService.save(interessadoRequestEditada.key, interessadoRequestEditada);
  }

  public async setInteressadoRequestAsCadastrado(requestKey: string, interessadoId: number): Promise<any> {
    let request: NovoInteressadoOfflineCommandParams = (await this.offlineStorageService.get(requestKey));

    request.interessadoCadastradoId = interessadoId;
    request.interessadoJaCadastrado = true;

    return this.offlineStorageService.save(request.key, request);
  }

  public deleteDocumento(documentoKey: string): Promise<void> {
    return this.offlineStorageService.delete(documentoKey);
  }

  public async getDocumentoInteressado(loteId: number, interessadoId: number, documentoId: number): Promise<ListarDocumentosInteressadoQueryResultItem | null> {
    const lote: ExibirLoteCompletoOfflineQueryResult = await this.offlineStorageService.get(`LOTE_${loteId}`);

    const interessado = lote.interessados.find((interessadoLote) => interessadoLote.id === interessadoId) || null;
    const documento = interessado?.documentos?.find((documentoInteressado) => documentoInteressado.id === documentoId) || null;

    return documento;
  }

  public async saveDocumentoRequestInteressado(identificadorInteressado: number | string, dadosDocumento: EnviarDocumentoInteressadoCommandParams): Promise<NovoDocumentoInteressadoOfflineCommandParams> {
    const keyPattern = `DOCUMENTO_REQUEST_INTERESSADO_${identificadorInteressado}`;
    let identificador = `${keyPattern}_${Math.random().toString()}`;
    
    let identificadorJaExiste = await this.offlineStorageService.get(identificador);

    while (identificadorJaExiste) {
      identificador = `${keyPattern}_${Math.random().toString()}`;
      identificadorJaExiste = await this.offlineStorageService.get(identificador);
    }

    let documentoInteressadoRequest: NovoDocumentoInteressadoOfflineCommandParams = {
      key: identificador,
      ...dadosDocumento
    };

    return this.offlineStorageService.save(documentoInteressadoRequest.key, documentoInteressadoRequest);
  }

  public async getAllDocumentosInteressado(loteId: number, interessadoId: number): Promise<ListarDocumentosInteressadoQueryResultItem[]> {
    const lote: ExibirLoteCompletoOfflineQueryResult = await this.offlineStorageService.get(`LOTE_${loteId}`);

    const interessado = lote.interessados.find((interessadoLote) => interessadoLote.id === interessadoId) || null;
    const documentosInteressado = interessado?.documentos || [];

    return documentosInteressado;
  }

  public async getDocumentoRequestInteressado(requestKey: string): Promise<NovoDocumentoInteressadoOfflineCommandParams> {
    const request = (await this.offlineStorageService.get(requestKey));
    return request;
  }

  public async getAllDocumentoRequestsInteressado(interessadoKey: string): Promise<NovoDocumentoInteressadoOfflineCommandParams[]> {
    const keyPattern = `DOCUMENTO_REQUEST_INTERESSADO_${interessadoKey}`;
    const documentosFromRequests = await this.offlineStorageService.getAll(keyPattern);

    return documentosFromRequests;
  }

}

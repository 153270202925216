import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../auth/user';
import { NetworkStatusService } from './network-status.service';
import { Role } from '../auth/roles.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private _subject: BehaviorSubject<User> = new BehaviorSubject<User>({} as User);
  private _redirectUri: string = '';

  constructor(private readonly _keycloakService: KeycloakService,
              private _networkStatusService: NetworkStatusService) {
    window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
        const loggedIn = localStorage.getItem('loggedIn');

        if(loggedIn == null) {
          // window.location.reload();
        }
      }
    }, false);
  }

  private async getCurrentUser(): Promise<User> {
    
    // if (this._networkStatusService.getConnectionStatus() === true) {
    //   return this.getCurrentUserOnline();
    // }

    return this.getCurrentUserOffline();
  }

  private async getCurrentUserOnline(): Promise<User> {
    console.log('GETTING USER ONLINE....');

    let user = await this._keycloakService.loadUserProfile();
    const tokenParsed = this._keycloakService.getKeycloakInstance().tokenParsed;

    const rolesFromToken = tokenParsed?.resource_access?.['reurb']?.roles;

    const roles: Role[] | null = rolesFromToken ? rolesFromToken.map((role: string) => { return Role[role as keyof typeof Role] }).filter((val) => val != null) : null;

    let currentUserName: string = (user.firstName ? user.firstName : '') + (user.lastName ? ' ' + user.lastName : '');
    let username: string = (user.username ? user.username : 'anonymous');

    let currentUser: User = {
      nome: currentUserName,
      username: username,
      roles: roles == null ? null : roles
    };

    console.log(roles == null ? 'VAZIO' : roles.map(role => role.toString()));
    
    return currentUser;
  }

  private async getCurrentUserOffline(): Promise<User> {

    console.log('GETTING USER OFFLINE....');

    try {
      const tokenParsed = this._keycloakService.getKeycloakInstance().tokenParsed;
      
      const rolesFromToken = tokenParsed?.resource_access?.['reurb']?.roles;

      const roles: Role[] | null = rolesFromToken ? rolesFromToken.map((role: string) => { return Role[role as keyof typeof Role] }).filter((val) => val != null) : null;
      
      const currentUserName: string = this._keycloakService.getKeycloakInstance().tokenParsed?.['name'];
      const username: string = this._keycloakService.getKeycloakInstance().tokenParsed?.['preferred_username'];

      const currentUser: User = {
        nome: currentUserName,
        username: username,
        roles: roles == null ? null : roles
      };
      
      return currentUser;
    } catch (error: any) {
      console.error(error);
    }
    
    return {} as User;
  }

  public getUser(): Observable<User> {
    this.getCurrentUser().then(
      (user) => {
        this._subject.next(user);
      },
      (error) => {
        console.error('error: falha ao obter usuário do keycloak' + error);
      }
    );

    return this._subject.asObservable();
  }

  public hasRole(role: Role): boolean {
    const tokenParsed = this._keycloakService.getKeycloakInstance().tokenParsed;

    const roles = tokenParsed?.resource_access?.['reurb']?.roles;

    return roles?.includes(role.toString()) || false;
  }


  public logout(): void {
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('kc_token');
    localStorage.removeItem('kc_refreshToken');

    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentUserTimeStamp');

    this._keycloakService.logout(this._redirectUri)
      .then(()=>{})
      .catch((erro) => {
        console.error(erro);
      });
  }

}

import { Injectable } from '@angular/core';
import { OfflineStorageService } from '../core/services/offline-storage.service';
import { NetworkStatusService } from '../core/services/network-status.service';
import { ExibirNucleoUrbanoOfflineQueryResult } from './models/nucleo-urbano-offline';
import { ExibirNucleoUrbanoQueryResult } from './models/nucleo-urbano';
import { ListarQuadrasQueryResultItem } from './quadras/models/quadra';
import { ListarQuadrasOfflineQueryResultItem } from './quadras/models/quadras-offline';

@Injectable({
  providedIn: 'root'
})
export class NucleosUrbanosOfflineService {

  constructor(
    private offlineStorageService: OfflineStorageService,
    private networkStatusService: NetworkStatusService
  ) { }

  //
  // Salva um Núcleo Urbano (Online) LOCALMENTE

  public saveNucleoUrbano(nucleoUrbano: ExibirNucleoUrbanoQueryResult): Promise<ExibirNucleoUrbanoOfflineQueryResult> {
    let newNucleoUrbano: ExibirNucleoUrbanoOfflineQueryResult = {
      key: `NUI_${nucleoUrbano.id.toString()}`,
      ...nucleoUrbano
    };

    return this.offlineStorageService.save(newNucleoUrbano.key, newNucleoUrbano);
  }

  //
  // Remove um Núcleo Urbano local

  public deleteNucleoUrbano(nucleoUrbanoId: number): Promise<void> {
    const key = `NUI_${nucleoUrbanoId}`;
    return this.offlineStorageService.delete(key);
  }

  //
  // Busca um Núcleo Urbano local pelo ID

  public getNucleoUrbano(nucleoUrbanoId: number): Promise<ExibirNucleoUrbanoOfflineQueryResult> {
    const key = `NUI_${nucleoUrbanoId}`;
    return this.offlineStorageService.get(key);
  }

  //
  // Lista todos os Núcleos Urbanos locais

  public async getAllNucleosUrbanos(): Promise<ExibirNucleoUrbanoOfflineQueryResult[]> {
    return this.offlineStorageService.getAll('NUI_');
  }

  //
  // Salva Quadra LOCALMENTE

  public saveQuadra(quadra: ListarQuadrasQueryResultItem, nucleoUrbanoId: number): Promise<ListarQuadrasOfflineQueryResultItem> {
    let newQuadra: ListarQuadrasOfflineQueryResultItem = {
      key: `QUADRA_NUI_${nucleoUrbanoId.toString()}_ID_${quadra.id.toString()}`,
      ...quadra
    };

    return this.offlineStorageService.save(newQuadra.key, newQuadra);
  }

  //
  // Remove Quadra Local

  public deleteQuadra(quadra: ListarQuadrasQueryResultItem, nucleoUrbanoId: number): Promise<void> {
    const key = `QUADRA_NUI_${nucleoUrbanoId.toString()}_ID_${quadra.id.toString()}`;
    return this.offlineStorageService.delete(key);
  }

  //
  // Busca Quadra Local

  public getQuadra(key: string): Promise<ListarQuadrasOfflineQueryResultItem> {
    return this.offlineStorageService.get(key);
  }

  // //
  // // Lista todas as Quadras Locais

  // public async getAllQuadras(): Promise<ListarQuadrasOfflineQueryResultItem[]> {
  //   return this.offlineStorageService.getAll('QUADRA_NUI_');
  // }

  //
  // Lista as Quadras Locais de Núcleo urbano

  public async getAllQuadrasFromNucleoUrbano(nucleoUrbanoId: number): Promise<ListarQuadrasOfflineQueryResultItem[]> {
    const key_pattern = `QUADRA_NUI_${nucleoUrbanoId.toString()}_ID_`;

    const quadrasFromNUI = (await this.offlineStorageService.getAll(key_pattern));
    return quadrasFromNUI;
  }

}
